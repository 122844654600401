

.editable-canvas-opacity-option-indicator {
    width: 50px;
    display: inline-block;
    margin-right: 5px;
}

li.editable-canvas-opacity-option .editable-canvas-opacity-option-input {
    display: inline-block;
    vertical-align: top;
    width: 100px;
    margin-left: 7px;
    margin-right: 7px;
    position: relative;
    right: 0;
}

.editable-canvas-opacity-option-label {
    padding-left: 10px ;
}

.popup-content .editable-canvas-toolbar li.editable-canvas-opacity-option {
    width: 100%;
    min-width: 100%;
    margin: 0;
    padding: 0 10px;
    box-sizing: border-box;
    text-align: right;
}

.popup-content li.editable-canvas-opacity-option .editable-canvas-opacity-option-input{
    float: right;
}

.popup-content .editable-canvas-toolbar li.editable-canvas-opacity-option .editable-canvas-opacity-option-indicator {
    float: none;
    text-align: left;
}