.btn-triangle .fa-play,
.drawer-tool-mouse-tooltip .fa-play{
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.drawer-tool-mouse-tooltip {
    opacity: 0;
    position:absolute;
    margin-left: 10px;
    margin-top: 10px;
    padding:5px;
    background: #f5f5f5;
    border: 1px solid #efefef;
    border-radius: 4px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.03);
    z-index: 99999;
    padding-left: 10px;
    padding-right: 10px;
    pointer-events: none;
    white-space: nowrap;
    transition: opacity 0.2s;
}