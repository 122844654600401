.crop-container {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 20000;
    background: #fff;
}

.crop-container .cr-image {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: 0 0;
    max-height: none;
    max-width: none;
}

.crop-container .cr-image {
    z-index: -2;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: 0 0;
    max-height: none;
    max-width: none;
    opacity: 0;
}

.crop-container .cr-boundary {
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    z-index: 1;
    width: 100%;
    height: 100%;
}

.crop-container .cr-viewport {
    position: absolute;
    border: 2px solid #fff;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    box-shadow: 0 0 2000px 2000px rgba(0, 0, 0, 0.5);
    z-index: 100;
    pointer-events: none;
}

.crop-container .cr-original-image {
    display: none;
}

.crop-container .cr-vp-circle {
    border-radius: 50%;
}

.crop-container .cr-overlay {
    z-index: 1;
    position: absolute;
    cursor: move;
}

.crop-container .cr-slider-wrap {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    margin: 15px auto;
    text-align: center;
}

.croppie-result {
    position: relative;
    overflow: hidden;
}

.croppie-result img {
    position: absolute;
}

.crop-container .cr-image,
.crop-container .cr-overlay,
.crop-container .cr-viewport {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.cr-viewport-resizer {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    width: inherit;
    height: inherit;
}

.cr-viewport-resizer.resizing {
    border: 2px dotted black;
    background: rgba(255, 255, 255, 0.2);
    margin: 0;
    box-sizing: content-box;
}

.cr-viewport-resizer-handler {
    position: absolute;
    left: 100%;
    top: 100%;
    width: 15px;
    height: 15px;
    margin: -10px 0 0 -10px;
    border: 2px solid #ccc;
    background: #5c5c5c;
    cursor: nwse-resize;
    pointer-events: auto;
}

.cr-controls-wrapper {
    position: absolute;
    z-index: 200;
    right: 5px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.cr-controls-wrapper .btn {
    display: block;
    margin: 5px auto;
}

/*************************************/
/***** STYLING RANGE INPUT ***********/
/*************************************/
/*http://brennaobrien.com/blog/2014/05/style-input-type-range-in-every-browser.html */
/*************************************/

.cr-slider {
    -webkit-appearance: none;
    /*removes default webkit styles*/
    /*border: 1px solid white; *//*fix for FF unable to apply focus style bug */
    width: 300px;
    /*required for proper track sizing in FF*/
    max-width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: transparent;
    cursor: pointer;
}

.cr-slider::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
    background: rgba(0, 0, 0, 0.5);
    border: 0;
    border-radius: 3px;
}

.cr-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #ddd;
    margin-top: -6px;
}

.cr-slider:focus {
    outline: none;
}

/*
.cr-slider:focus::-webkit-slider-runnable-track {
background: #ccc;
}
*/

.cr-slider::-moz-range-track {
    width: 100%;
    height: 3px;
    background: rgba(0, 0, 0, 0.5);
    border: 0;
    border-radius: 3px;
}

.cr-slider::-moz-range-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #ddd;
    margin-top: -6px;
}

/*hide the outline behind the border*/
.cr-slider:-moz-focusring {
    outline: 1px solid white;
    outline-offset: -1px;
}

.cr-slider::-ms-track {
    width: 100%;
    height: 5px;
    background: transparent;
    /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
    border-color: transparent; /*leave room for the larger thumb to overflow with a transparent border */
    border-width: 6px 0;
    color: transparent; /*remove default tick marks*/
}

.cr-slider::-ms-fill-lower {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.cr-slider::-ms-fill-upper {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.cr-slider::-ms-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #ddd;
    margin-top: 1px;
}

.cr-slider:focus::-ms-fill-lower {
    background: rgba(0, 0, 0, 0.5);
}

.cr-slider:focus::-ms-fill-upper {
    background: rgba(0, 0, 0, 0.5);
}

/*******************************************/

/***********************************/
/* Rotation Tools */
/***********************************/
.cr-rotate-controls {
    position: absolute;
    bottom: 5px;
    left: 5px;
    z-index: 1;
}

.cr-rotate-controls button {
    border: 0;
    background: none;
}

.cr-rotate-controls i:before {
    display: inline-block;
    font-style: normal;
    font-weight: 900;
    font-size: 22px;
}

.cr-rotate-l i:before {
    content: '↺';
}

.cr-rotate-r i:before {
    content: '↻';
}

.image-crop-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 201;
    overflow: hidden;
}

.cr-image {
    max-width: 100%;
    max-height: 100%;
    top: 50%;
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.cr-image-fullsize {
    opacity: 0;
}

.imager-crop-container {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1300;
}

.imager-crop-canvas {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.crop-corner {
    position: absolute;
    width: 14px;
    height: 14px;
    border: 1px solid rgb(60, 58, 58);
    background: rgb(255, 255, 255);
    z-index: 1301;
}

.crop-top-left {
    left: 0;
    top: 0;
    cursor: nwse-resize;
}

.crop-top-right {
    right: 0;
    top: 0;
    cursor: nesw-resize;
}

.crop-bottom-right {
    right: 0;
    bottom: 0;
    cursor: nwse-resize;
}

.crop-bottom-left {
    left: 0;
    bottom: 0;
    cursor: nesw-resize;
}

.crop-border {
    /*background: url(../../assets/border.gif);*/
    position:absolute;
}

.crop-border.crop-border-top {
    width: 100%;
    height: 1px;
    top: 4px;
}

.crop-border.crop-border-right {
    width: 1px;
    height: 100%;
    right: 2px;
}

.crop-border.crop-border-bottom {
    width: 100%;
    height: 1px;
    bottom: 2px;
}

.crop-border.crop-border-left {
    width: 1px;
    height: 100%;
    left: 4px;
}