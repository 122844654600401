.zoom-tooltip {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #ccc;
    padding: 5px;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    z-index: 20000000;
    border-radius: 5px;
    opacity: 0.85;

    pointer-events: none;
    transition: opacity 500ms linear;
}

.zoom-tooltip.transparent-tooltip {
    opacity:0;
}