ul.editable-canvas-toolbar li.editable-canvas-brushsize {
    width: auto;
    padding-left: 10px !important;
    padding-right: 10px;
}


li.editable-canvas-brushsize .editable-canvas-brushsize-indicator{
    width: 50px;
    display: inline-block;
    margin-right: 5px;
}

.popup-wrapper .popup-content .editable-canvas-toolbar li.editable-canvas-brushsize {
    width: 100%;
    min-width: 100%;
    margin: 0;
    padding: 0 10px;
    box-sizing: border-box;
    text-align: right;
}

.popup-wrapper .popup-content .editable-canvas-toolbar li.editable-canvas-brushsize .editable-canvas-brushsize-input{
    float: right;
}

.popup-wrapper .popup-content li.editable-canvas-brushsize .editable-canvas-brushsize-indicator {
    float: none;
    text-align: left;
}


li.editable-canvas-brushsize .editable-canvas-brushsize-input {
    display: inline-block;
    vertical-align: top;
    width: 100px;
    margin-left: 7px;
    margin-right: 7px;
    position: relative;
    right: 0;
}
